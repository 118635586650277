import React, { useState } from 'react';
import ModalContext from './ModalContext';

function ModalProvider({ children }) {  
  const [validateModalOpen, setValidateModalOpen] = useState(false);
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);
  const [downloadFlowModalOpen, setDownloadFlowModalOpen] = useState(false);
  const [syncAnswerModalOpen, setSyncAnswerModalOpen] = useState(false);
  const [reloadFlowModalOpen, setReloadFlowModalOpen] = useState(false);
  const [filesArrayModalOpen, setFilesArrayModalOpen] = useState(false);
  const [answerNotResponseModalOpen, setAnswerNotResponseModalOpen] = useState(false);
  const [answerNotFileModalOpen, setAnswerNotFileModalOpen] = useState(false);
  const [flowFinishedModalOpen, setFlowFinishedModalOpen] = useState(false);
  const [attachmentNotFoundModalOpen, setAttachmentNotFoundModalOpen] = useState(false);

  const context = {
    validateModalOpen, setValidateModalOpen,
    loadingModalOpen, setLoadingModalOpen,
    downloadFlowModalOpen, setDownloadFlowModalOpen,
    syncAnswerModalOpen, setSyncAnswerModalOpen,
    reloadFlowModalOpen, setReloadFlowModalOpen,
    filesArrayModalOpen, setFilesArrayModalOpen,
    answerNotResponseModalOpen, setAnswerNotResponseModalOpen,
    answerNotFileModalOpen, setAnswerNotFileModalOpen,
    flowFinishedModalOpen, setFlowFinishedModalOpen,
    attachmentNotFoundModalOpen, setAttachmentNotFoundModalOpen,
  }

  return (
    <ModalContext.Provider value={ context }>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider;