import React from 'react'
import Header from './../../components/layout/Header'
import Footer from './../../components/layout/Footer'
import Menu from './../../components/Menu/index'
import './style.css'
import LoginInfo from '../../components/layout/LoginInfo'

export default function MainMenu () {
  return (
    <section className="h-screen h-[calc(var(--vh)*100)] bg-gray-200 w-full text-white">
      <Header />
      <LoginInfo />
      <div className='w-full h-[calc(100%-7rem)] -mt-[3rem] flex items-start justify-center relative'>
        <Menu />
      </div>
      <Footer />
    </section>
  )
}